<template>
    <error-boundary :stop-propagation="true">
        <v-app class="app" :style="tpAppStyles">
            <v-layout full-height>
                <site-header
                    v-model:current-language="language"
                    :menu-items="menuItems"
                    :is-logged-in="userStore.isLoggedIn"
                    :user-info="userStore.getProfile"
                    class="app__header"
                />

                <v-main>
                    <v-overlay
                        :model-value="mainStore.isLoading"
                        contained
                        opacity="1"
                        scrim="white"
                        class="d-flex align-center justify-center"
                    >
                        <v-progress-circular color="primary" indeterminate size="64" />
                    </v-overlay>

                    <v-container v-if="mainStore.ready" fluid class="py-10 h-100" style="position: relative; max-width: 600px">
                        <router-view />
                    </v-container>
                </v-main>
            </v-layout>

            <silent-login v-if="config.silentLoginEnabled && userLoaded && !userStore.isLoggedIn" />
        </v-app>
    </error-boundary>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { computed, getCurrentInstance, onMounted, provide, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import useMainStore from '@/features/common/stores/mainStore';
import { SiteHeader, useSiteHeader } from '@geta/kolumbus-frontend/components';
import { SilentLogin, ErrorBoundary } from '@/features/common/components';
import { useUserStore } from '@/features/authentication';
import { AppProvideKey } from './injectionKeys';
import { createGtm } from 'vue-gtm';
import { routeNames } from './router';
import { i18nRoute } from './i18n';

const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();
const userStore = useUserStore();
const { menuItems, language, config } = storeToRefs(mainStore);
const { height: siteHeaderHeight } = useSiteHeader();
const userLoaded = ref(false);

const tpAppStyles = computed(() => ({
    '--tp-app__header-height': `${siteHeaderHeight.value}px`
}));

const currentTime = ref(new Date());
// const ORDER_TRAVELCARD_URL = ref('');
const gmapsApiKey = ref('');

provide(AppProvideKey, { currentTime, /*ORDER_TRAVELCARD_URL,*/ gmapsApiKey });

watch(language, async (value, oldValue) => {
    if (value !== oldValue) {
        try {
            await router.replace({ params: { locale: value }, query: route.query });
        } catch (e) {
            mainStore.registerError(e);
            router.push(i18nRoute({ name: routeNames.home }));
        }
    }
});

const instance = getCurrentInstance();

onMounted(async () => {
    setInterval(() => (currentTime.value = new Date()), 1000);

    await mainStore.init();

    if (config.value?.silentLoginEnabled) {
        await userStore.loadUser();
        userLoaded.value = true;
        userStore.loadProfile(menuItems?.value?.loginLink);
    }

    if (instance && config.value?.googleTagManagerConfig?.id) {
        instance.appContext.app.use(createGtm({ id: config.value.googleTagManagerConfig.id }));
    }

    if (mainStore.config.googleMapsConfig.apiKey) {
        gmapsApiKey.value = mainStore.config.googleMapsConfig.apiKey;
    }

    mainStore.ready = true;
});
</script>

<style lang="scss">
.app {
    &__header {
        z-index: 1000;
    }

    &__drawer {
        z-index: 900;
    }
}

.v-main {
    z-index: 1;

    .v-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
</style>
